import moment from 'moment'

export function camelCaseToTitle(string) {
  if (typeof string !== 'string') string = `${string}`
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/:([A-Z])/g, ' $1')
}

export function isInterval(aggregation) {
  return aggregation && aggregation[0] === 'P'
}

export function aggregationWeight(aggregation, _isInterval = isInterval(aggregation)) {
  let weight = 0
  if (aggregation) {
    if (_isInterval) {
      weight = +moment.duration(aggregation)
    } else {
      weight = +aggregation.match(/(\d+)/)[0]
      let lastChar = aggregation[aggregation.length - 1]
      if (lastChar === 'T') weight *= 1_000_000_000_000
      if (lastChar === 'B') weight *= 1_000_000_000
      if (lastChar === 'M') weight *= 1_000_000
      if (lastChar === 'K') weight *= 1_000
    }
  }
  return weight
}

const isAggregation = string =>
  isInterval(string) || string.match(/^[VDCTBM][0-9]/)

const datasetCache = {}

export function parseDataset(datasetString) {
  if (datasetCache[datasetString]) return datasetCache[datasetString]
  if (typeof datasetString === 'object') return datasetString
  if (!datasetString) return {}
  let tokens = datasetString.split('-')
  let source
  let name
  let aggregation
  let base
  let type = 'trade'

  let quote

  if (tokens.length === 3 && isAggregation(tokens[2])) {
    source = tokens[0]
    name = tokens[1]
    aggregation = tokens[2]
    type = 'bag'
  } else {
    source = tokens[0]
    base = tokens[1]
    quote = tokens[2]
    name = `${base}-${quote}`
    aggregation = tokens[3]
    type = 'candle'
  }
  const _isInterval = isInterval(aggregation)
  const intervalDuration = _isInterval ? moment.duration(aggregation) : null
  const [primaryAggregation, secondaryAggregation] = aggregation ? aggregation.split(':') : []
  const tradeDataset = `${source}-${name}`
  const primaryAggregationDataset = `${source}-${name}-${primaryAggregation}`
  let weight = aggregationWeight(primaryAggregation, _isInterval, intervalDuration)
  return datasetCache[datasetString] = {
    type,
    source,
    name,
    base,
    quote,
    interval: isInterval ? aggregation : null,
    isInterval: _isInterval,
    intervalDuration,
    datasetString,
    aggregation,
    primaryAggregation,
    secondaryAggregation,
    tradeDataset,
    primaryAggregationDataset,
    weight,
  }
}

export function anySource(datasetString) {
  const [_, base, quote, aggregation] = datasetString.split('-')
  return `XX-${base}-${quote}-${aggregation}`
}

export function withBase(datasetString, base) {
  const [source, _, quote, aggregation] = datasetString.split('-')
  return `${source}-${base}-${quote}-${aggregation}`
}

// From Jason Larke: https://stackoverflow.com/questions/26156292/trim-specific-character-from-a-string
export function trim(str, ch) {
  if (!str) return str
  let start = 0
  let end = str.length

  while (start < end && str[start] === ch) {
    ++start
  }

  while (end > start && str[end - 1] === ch) {
    --end
  }

  return (start > 0 || end < str.length) ? str.substring(start, end) : str
}
