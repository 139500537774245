import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {randomString} from '../components/util/Language'
import {mapValues, set} from 'lodash'
import useWebSocket from './useWebSocket'

export const StrategyContext = React.createContext({})

export const StrategyProvider = ({children}) => {
  const [ownerIds, setOwnerIds] = useState({})
  const metrics = useRef({})

  const ownerIdsSubscribed = Object.entries(ownerIds)
    .filter(([_, subscriptions]) => subscriptions.length > 0)
    .map(([ownerIds]) => ownerIds)

  const {websocket, connected} = useWebSocket('/ws/strategy', ({data}) => {
    data = JSON.parse(data)
    data.received = +new Date()
    set(metrics.current, `${data.ownerId}.${data.category}:${data.key}`, data)
  })

  useEffect(() => {
    if (websocket?.readyState !== 1) return
    websocket.send(`ownerIds:${ownerIdsSubscribed.join(',')}`)
  }, [ownerIdsSubscribed, websocket, connected])

  const subscribeTo = (ownerIds) => {
    const subscription = randomString(12)
    setOwnerIds(s => {
      return ownerIds.reduce((acc, ownerId) => {
        const existing = s[ownerId] || []
        return ({...s, [ownerId]: [...existing, subscription]})
      }, s)
    })
    return subscription
  }

  const unsubscribeFrom = (subscription) => {
    setOwnerIds(s => mapValues(s, existing => existing.filter(it => it !== subscription)))
  }

  return (
    <StrategyContext.Provider value={{metrics, subscribeTo, unsubscribeFrom}}>
      {children}
    </StrategyContext.Provider>
  )
}

export const useMetrics = (ownerIds) => {
  const {metrics: allMetrics, subscribeTo, unsubscribeFrom} = useContext(StrategyContext)
  const [myMetrics, setMyMetrics] = useState({})

  const check = useCallback(() => {
    if (!ownerIds || ownerIds.length === 0) return
    const updated = {}
    let changed = false
    ownerIds.forEach(ownerId => {
      const latest = allMetrics.current[ownerId]
      const previous = myMetrics[ownerId]
      changed ||= !previous ? latest : previous.received !== latest?.received
      updated[ownerId] = latest
    })
    if (changed) setMyMetrics(updated)
  }, [ownerIds])

  useEffect(() => {
    let callback = setInterval(check, 100)
    return () => clearInterval(callback)
  }, [JSON.stringify(ownerIds), check])

  useEffect(() => {
    const subscription = subscribeTo(ownerIds)
    return () => unsubscribeFrom(subscription)
  }, [])

  return {metrics: myMetrics}
}
