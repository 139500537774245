import React, {useState} from 'react'
import {CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from '@coreui/react-pro'

export const ConfirmationContext = React.createContext({})

export const ConfirmationProvider = ({children}) => {
  const [{callback, message}, setCallback] = useState({})

  const withConfirmation = (message, callback) => {
    setCallback({callback, message})
  }

  const execute = () => {
    try {
      callback()
    } finally {
      setCallback({})
    }
  }

  return (
    <ConfirmationContext.Provider value={{withConfirmation}}>
      <CModal visible={!!callback} onClose={() => setCallback({})}>
        <CModalHeader onClose={() => setCallback({})}>
          <CModalTitle>Are you sure?</CModalTitle>
        </CModalHeader>
        <CModalBody>{message}</CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setCallback({})}>Cancel</CButton>
          <CButton color="primary" onClick={() => execute()}>Ok</CButton>
        </CModalFooter>
      </CModal>
      {children}
    </ConfirmationContext.Provider>
  )
}
