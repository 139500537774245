import React, {useState} from 'react'
import {CModal, CModalBody, CModalHeader, CModalTitle} from '@coreui/react-pro'

export const ModalContext = React.createContext({})

export const ModalProvider = ({children}) => {
  const [{title, body, size}, setModal] = useState({})

  const withModal = (title, body, size) => {
    setModal({title, body, size})
  }

  const closeModal = () => {
    setModal({})
  }

  return (
    <ModalContext.Provider value={{withModal, closeModal}}>
      <CModal visible={!!title} onClose={() => setModal({})} size={size}>
        <CModalHeader onClose={() => setModal({})}>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>{body}</CModalBody>
      </CModal>
      {children}
    </ModalContext.Provider>
  )
}
