import {parseDataset} from '../util/text'
import moment from 'moment/moment'
import {flattenMetric} from '../util/objects'
import {mapValues} from 'lodash'

export const prepareDto = (type, dto) => {
  switch (type) {
    case 'runs':
      return parseRun(dto)
    case 'ledgers':
      return parseLedger(dto)
    case 'funds':
      return parseFund(dto)
    case 'strategies':
      return parseStrategy(dto)
    case 'entities':
      return parseEntity(dto)
    case 'universe':
      return parseUniverseAggregated(dto)
    case 'workspaces':
      return parseWorkspace(dto)
    case 'emos':
      return parseExecutionModel(dto)
    default:
      return dto
  }
}

export const parseUniverseAggregated = (dto) => {
  // The API sends the datasets in a denormalized form, we need to parse them.
  if (dto.aggregations) {
    return dto.aggregations.map(it => parseDataset(`${dto.dataset}-${it}`))
  } else {
    return dto
  }
}

export const parseUniverse = (universe) => {
  const dataset = parseDataset(universe.dataset)
  const epoch = moment.utc(universe.epoch)
  const terminus = universe.terminus && moment.utc(universe.terminus)
  const size = moment.utc() - epoch
  return {...universe, dataset, epoch, terminus, size}
}

export const parseWorkspace = run => {
  run.created = moment.utc(run.created)
  run.updated = moment.utc(run.updated)
  return run
}

export const parseRun = run => {
  run.dataset = parseDataset(run.dataset)
  run.created = moment.utc(run.created)
  run.updated = moment.utc(run.updated)
  return run
}

export const parseLedger = ledger => {
  ledger.timestamp = moment.utc(ledger.timestamp)
  return ledger
}

export const parseFund = fund => {
  fund.created = moment.utc(fund.created)
  fund.updated = moment.utc(fund.updated)
  return fund
}

export const parseStrategy = strategy => {
  strategy.created = moment.utc(strategy.created)
  strategy.updated = moment.utc(strategy.updated)
  return strategy
}

export const parseEntity = entity => {
  entity.created = moment.utc(entity.created)
  entity.updated = moment.utc(entity.updated)
  return entity
}

export const parseTrade = trade => {
  trade.created = moment.utc(trade.created)
  trade._createdInt = +trade.created
  trade.updated = moment.utc(trade.updated)
  trade._updatedInt = +trade.updated
  trade.opened = trade.opened && moment.utc(trade.opened)
  trade._openedInt = trade.opened && +trade.opened
  trade.closed = trade.closed && moment.utc(trade.closed)
  trade._closedInt = trade.closed && +trade.closed
  trade.dataset = parseDataset(trade.dataset)
  return trade
}

export const parseBag = bag => {
  bag.start = moment.utc(bag.start)
  bag._startInt = +bag.start
  bag.end = moment.utc(bag.end)
  bag._endInt = +bag.end
  return bag
}

export const parseRiskLayer = layer => {
  layer.updated = moment.utc(layer.updated)
  layer.created = moment.utc(layer.created)
}

export const parseOrder = order => {
  order.created = moment.utc(order.created)
  order._createdInt = +order.created
  order.updated = moment.utc(order.updated)
  order._updatedInt = +order.updated
  order.expiry = order.expiry && moment.utc(order.expiry)
  order._expiryInt = +order.expiry
  order.cancelled = order.cancelled && moment.utc(order.cancelled)
  order._cancelledInt = +order.cancelled
  order.executed = order.executed && moment.utc(order.executed)
  order._executedInt = +order.executed
  order.triggerPrice = order.triggerPrice && parseFloat(order.triggerPrice)
  order._triggerPrice = +order.triggerPrice
  order.newOrder = order.newOrder && parseNewOrder(order.newOrder)
  return order
}

export const parseExecutionModel = model => {
  model.created = moment.utc(model.created)
  model._createdInt = +model.created
  model.updated = moment.utc(model.updated)
  model._updatedInt = +model.updated
  return model
}

export const parseNewOrder = newOrder => {
  newOrder.dataset = newOrder.dataset && parseDataset(newOrder.dataset)
  return newOrder
}

export const parseCandle = candle => {
  candle.start = moment.utc(candle.start)
  candle._startInt = +candle.start
  candle.end = moment.utc(candle.end)
  candle._endInt = +candle.end
  candle.volume = candle.bidVolume + candle.askVolume
  return candle
}

export const parseCandleForChart = candle => {
  const {start, end, ...rest} = candle
  const volume = candle.bidVolume + candle.askVolume
  const dataset = candle.dataset?.datasetString || candle.dataset
  const result = {[dataset]: {...rest, volume}}
  result.start = moment.utc(start)
  result.end = moment.utc(end)
  result._startInt = +result.start
  result._endInt = +result.end
  result.date = result.start.toDate()
  return result
}

export const parseMetric = metric => {
  const flattented = {...flattenMetric(metric)}
  flattented.date = moment.utc(metric.timestamp)
  flattented._dateInt = +flattented.date
  return flattented
}

export const parseStrategySummary = summary => {
  summary.startDate = moment(summary.startDate)
  summary.endDate = moment(summary.endDate)
  return summary
}

export const parseExchangeOrders = orders => {
  const parsed = mapValues(orders, it => it.map(parseExchangeOrder))
  return parsed
}

export const parseExchangeOrder = order => {
  order.timestamp = moment.utc(order.timestamp)
  order._timestampInt = +order.timestamp
  return order
}
