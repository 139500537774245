import React, {useEffect} from 'react'
import useForm from '../hooks/useForm'
import {useRefData} from './RefData'
import {isObjEmpty} from '../util/objects'
import useAltoClient from '../api/UseAltoClient'

export const workspaceDefault = {workspaceId: null}
export const WorkspaceContext = React.createContext(workspaceDefault)
const STORAGE_KEY = 'com.blackalto.workspace'

export const WorkspaceProvider = ({children}) => {
  const {upsertWorkspace} = useAltoClient()
  const initialValues = localStorage.getItem(STORAGE_KEY) ? JSON.parse(localStorage.getItem(STORAGE_KEY)) : workspaceDefault
  const {workspaces} = useRefData('workspaces')
  const {values: {workspaceId}, handleChange, setValue} = useForm({initialValues})
  const workspace = workspaces?.[workspaceId]

  useEffect(() => {
    if (!workspaceId) return
    localStorage.setItem(STORAGE_KEY, JSON.stringify({workspaceId}))
  }, [workspaceId])

  const setWorkspaceId = (id) =>
    setValue('workspaceId', id)

  useEffect(() => {
    const loadWorkspaces = async () => {
      if (!workspaces) return
      if (isObjEmpty(workspaces)) {
        const {id} = await upsertWorkspace({name: 'Default Workspace', labels: {}, description: '', metrics: {}})
        console.info(`Created default workspace [${id}]`)
        setWorkspaceId(id)
      } else if (workspaces[workspaceId] === undefined) {
        setWorkspaceId(Object.keys(workspaces)[Object.keys(workspaces).length - 1])
      }
    }

    loadWorkspaces()
  }, [workspaces])

  return (
    <WorkspaceContext.Provider value={{workspaceId, workspaces, workspace, handleChange, setWorkspaceId}}>
      {children}
    </WorkspaceContext.Provider>
  )
}
