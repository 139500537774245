import {createContext} from 'react'

// set initial value of user to null (pre-login)
export const EnvContext = createContext(null)

export const DEV_ENV = 'dev'
export const PROD_ENV = 'prod'
export const LOCAL_ENV = 'local'

// Not particularly clever but it'll work for now!
export function determineEnv() {
  let env = DEV_ENV
  if (window.location.href.indexOf('prod.') > -1) {
    env = PROD_ENV
  }
  if (window.location.href.indexOf('localhost') > -1) {
    env = LOCAL_ENV
  }
  if (env !== PROD_ENV) {
    const link = document.querySelector('link[rel~=\'icon\']')
    if (link) link.href = link.href.replace('favicon.svg', `favicon-${env}.svg`)
  }
  return env
}
