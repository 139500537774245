import React, {useEffect, useState} from 'react'
import useForm from '../hooks/useForm'
import moment from 'moment'

const dateRangeDefault = {from: moment().startOf('month').toISOString(), to: moment().startOf('day').toISOString()}
export const DateRangeContext = React.createContext(dateRangeDefault)
const STORAGE_KEY = 'com.blackalto.daterange'

export const DateRangeProvider = ({children}) => {
  const initialValues = localStorage.getItem(STORAGE_KEY) ? JSON.parse(localStorage.getItem(STORAGE_KEY)) : dateRangeDefault
  const {values, handleChange, setValue, setValues} = useForm({initialValues})
  const [navNonce, setNavNonce] = useState(0)
  const {from, to} = values || {}

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(values))
  }, [values])

  const setDateRange = (event, isKeyPress) => {
    if (!isKeyPress) setNavNonce(it => it + 1)
    setValues(event)
  }

  return (
    <DateRangeContext.Provider value={{daterange: values, from, to, navNonce, handleChange, setDateRange, setValue}}>
      {children}
    </DateRangeContext.Provider>
  )
}
