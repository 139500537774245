import {useState} from 'react'
import {cloneDeep, set} from 'lodash'

export default function useForm({initialValues, storageKey} = {}) {
  const load = () => {
    if (!storageKey) return undefined
    try {
      return JSON.parse(localStorage.getItem(storageKey))
    } catch (e) {
      localStorage.removeItem(storageKey)
    }
  }
  const [values, setValues] = useState(load() || initialValues || {})

  const setValuesAndStore = (valuesCallback) => {
    setValues(v => {
      let updated = valuesCallback(cloneDeep(v))
      if (storageKey) localStorage.setItem(storageKey, JSON.stringify(updated))
      return updated
    })
  }

  const setValue = (k, v) => {
    setValuesAndStore(o => {
      return set(o, k, v)
    })
  }

  const handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked :
      event.target.type === 'range' ? +event.target.value :
      event.target.value
    setValue(event.target.name, value)
  }

  const addValues = (toAdd) =>
    setValuesAndStore(v => ({...v, ...toAdd}))

  return {
    handleChange,
    setValues,
    addValues,
    setValue,
    values,
  }
}
