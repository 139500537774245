import axiosImported from 'axios'
import moment from 'moment'
import React, {createContext} from 'react'

export const ClientContext = createContext(null)

export const ClientProvider = ({children}) => {
  const axios = axiosImported.create()

  const authenticate = async (username, password, entityId) => {
    await axios.post('/api/auth/login', {username, password, entityId})
  }

  const clearTokens = async () => {
    await axios.post('/api/auth/logout')
  }

  const ParamsSerializer = {
    indexes: null,
    encode: param => param instanceof moment ? param.toISOString() : param,
  }

  axios.interceptors.request.use(
    (config) => {
      config.paramsSerializer = ParamsSerializer
      return config
    },
    (error) => Promise.reject(error),
  )

  const handleReject = async function (error) {
    const originalRequest = error.config
    // We only want to retry if the error is 401.
    if (!error.response || error.response.status !== 401) {
      return Promise.reject(error)
    }
    // If we already tried refreshing the token, don't do it again.
    if (originalRequest._retry) {
      return Promise.reject(error)
    }
    // Retry the request
    return await retryRequest(originalRequest, error)
  }

  const useRefreshToken = async (entityId) => {
    console.info('useRefreshToken', entityId)
    const post = {grant_type: 'refresh_token', entityId}
    const {data: {access_token}} = await axiosImported.post('/api/auth/oauth/access_token', post)
    return access_token
  }

  const retryRequest = async (originalRequest, error) => {
    originalRequest._retry = true
    try {
      await useRefreshToken()
      return axios(originalRequest)
    } catch (e) {
      console.info('retryRequest: failed to get new token: ' + e)
      throw error
    }
  }

  axios.interceptors.response.use((response) => response, it => handleReject(it))

  return (
    <ClientContext.Provider value={{axios, useRefreshToken, clearTokens, authenticate}}>
      {children}
    </ClientContext.Provider>
  )
}
