import React, {useRef, useState} from 'react'
import {CToast, CToastBody, CToaster, CToastHeader} from '@coreui/react-pro'

export const ToastContext = React.createContext()

export const ToastProvider = ({children}) => {
  const [toast, addToast] = useState(0)
  const toaster = useRef()

  const altoast = (message) => (
    <CToast>
      <CToastHeader closeButton>
        <strong className="me-auto">Alto</strong>
      </CToastHeader>
      <CToastBody>{message}</CToastBody>
    </CToast>
  )

  const showToast = (message) => addToast(altoast(message))

  return (
    <ToastContext.Provider value={{showToast}}>
      <CToaster ref={toaster} push={toast} placement="top-end" />
      {children}
    </ToastContext.Provider>
  )
}
