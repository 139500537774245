import React, {useEffect} from 'react'
import useForm from '../hooks/useForm'
import {useRefData} from './RefData'
import {runsForFund} from '../util/filters'
import {uniq} from 'lodash'
import useAuth from '../hooks/useAuth'

export const fundDefault = {fundId: null}
export const FundContext = React.createContext(fundDefault)

export const FundProvider = ({children}) => {
  const {entityId} = useAuth()
  const storageKey = `com.blackalto.${entityId}fund`

  const {ledgers, funds, runs} = useRefData('ledgers', 'funds', 'runs')
  const {values = {}, handleChange, setValue, setValues} = useForm({})
  const {fundId, runId} = values
  const fund = funds?.[fundId]
  const fundRuns = runsForFund(runs || {}, fundId)
  const fundRunIds = fundRuns?.map(it => it.id)
  const run = fundRuns?.find(it => it.id === runId)
  const runDataset = run?.dataset

  const fundDatasets = fundRuns.map(run => {
    return uniq(Object.keys(ledgers?.[run.id]?.datasets || {}).concat(run.dataset.tradeDataset))
  })

  useEffect(() => {
    if (!entityId) return
    const initialValues = localStorage.getItem(storageKey) ? JSON.parse(localStorage.getItem(storageKey)) : fundDefault
    setValues(initialValues)
  }, [entityId])

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify({fundId, runId}))
  }, [values])

  useEffect(() => {
    if (!funds) return
    if (!fundId || funds[fundId] === undefined) {
      setValue('fundId', Object.keys(funds)[0])
    }
  }, [funds])

  let xtpId = fund && fund.xtpId
  return (
    <FundContext.Provider value={{fundId, fund, runId, run, xtpId, fundRuns, fundRunIds, fundDatasets, funds, runDataset, handleChange}}>
      {children}
    </FundContext.Provider>
  )
}
