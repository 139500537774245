export const functor = (v) => typeof v === 'function' ? v : () => v

/**
 * Retrieve a fixed number of elements from an array, evenly distributed but
 * always including the first and last elements.
 *
 * @param   {Array} items - The array to operate on.
 * @param   {number} n - The number of elements to extract.
 * @returns {Array}
 */
export function distributedCopy(items, n) {
  if (items.length === 0) return []
  const elements = [items[0]]
  const totalItems = items.length - 2
  const interval = Math.floor(totalItems / (n - 2))
  for (let i = 1; i < n - 1; i++) elements.push(items[i * interval])
  elements.push(items[items.length - 1])
  return elements
}

/**
 * Thanks to https://stackoverflow.com/questions/10726909/random-alpha-numeric-string-in-javascript.
 * @returns {string}
 */
export function randomString(length, chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') {
  let result = ''
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}

export const sleep = (ms) =>
  new Promise(resolve => setTimeout(resolve, ms))
