import React from 'react'

const curve = (x1, y1, x2, y2, x, y) => `C ${x1},${y1} ${x2},${y2} ${x},${y} `
const move = () => `M ${27.95},${74.15} `

export const Logo = (
  {
    id,
    size = 100,
    dark = false,
    wide = false,
    transparent = false,
    noText = false,
    width = wide ? size * 3.15 : size,
    ...props
  }) => {
  const margin = wide ? 20 : 15
  const background = transparent ? 'transparent' : (dark ? 'black' : 'white')
  const color1 = dark ? 'white' : 'black'

  const path = move() +
    curve(14.9, 74.4, 7.50, 68.6, 5.10, 60.9) +
    curve(2.10, 50.6, 7.70, 40.0, 16.6, 37.2) +
    curve(9.00, 24.0, 16.2, 9.00, 27.7, 5.30) +
    curve(41.3, 0.85, 54.7, 8.30, 56.8, 20.6) +
    curve(62.7, 15.8, 72.7, 17.9, 77.2, 22.4) +
    curve(81.6, 27.0, 84.3, 35.2, 80.0, 40.2) +
    curve(91.2, 40.8, 96.3, 49.9, 95.2, 59.0) +
    curve(94.6, 68.2, 84.7, 75.1, 75.6, 74.2) +
    ' Z'

  const Cloud = () => <path style={{fill: 'none', stroke: color1, strokeWidth: 8, strokeLinejoin: 'round'}} d={path} />
  const Name = ({alignmentBaseline, fontSize}) => (
    <text style={{fontSize, fontFamily: 'Avenir', letterSpacing: 0}}>
      <tspan alignmentBaseline={alignmentBaseline} style={{fontWeight: 'bold', fill: color1}} x={0}>Black</tspan>
      <tspan alignmentBaseline={alignmentBaseline} style={{fontWeight: 200, fill: '#61a8ff'}} x={fontSize * 2.45}>Alto</tspan>
    </text>
  )

  const Wide = () => (
    <svg id={id} {...props} width={width} height={size} viewBox={`0 0 ${width} ${size}`} version="1.1">
      <g transform={`scale(${1.28 * size / 100})`}>
        <rect x={0} y={0} width={100 * 8} height={100} style={{fill: background}} />
        <g transform={`translate(${margin / 1.5}, ${margin * 0.8}) scale(${(100 - margin * 2) / 100})`}>
          <Cloud />
        </g>
        <g transform={`translate(${100 - margin}, ${margin * 1.2}) scale(${(100 - margin * 2) / 100})`}>
          <Name alignmentBaseline="hanging" fontSize={100 - margin * 2} />
        </g>
      </g>
    </svg>
  )

  const Square = () => (
    <svg id={id} {...props} width={width} height={size} viewBox={`0 0 ${width} ${size}`} version="1.1">
      <g transform={`scale(${size / 100})`}>
        <rect x={0} y={0} width={100} height={100} style={{fill: background}} />
        <g transform={`translate(${margin}, ${(noText ? size / 3 : 0) + margin * 0.9}) scale(${(100 - margin * 2) / 100})`}>
          <Cloud />
        </g>
        {!noText && <g transform={`translate(${margin * 0.8}, ${100 - margin * 0.9}) scale(${(100 - margin * 2) / 100})`}>
          <Name alignmentBaseline="auto" fontSize={25} />
        </g>}
      </g>
    </svg>
  )

  return wide ? <Wide /> : <Square />
}
