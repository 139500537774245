import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from '../../hooks/useAuth'
import {SpinnerContainer} from '../../components/util/Spinner'

PrivateRoute.propTypes = {
  render: PropTypes.any,
}

export default function PrivateRoute({render: Component, ...rest}) {
  const {auth, isLoading} = useAuth()

  if (isLoading) return <SpinnerContainer />

  if (auth === null) return <Redirect to="/login" />

  return <Route {...rest} render={(props) => <Component {...props} />} />
}
