import React from 'react'

const MouseDataContext = React.createContext({})

export const MouseDataProvider = ({children}) => {
  const [mouseData, setMouseData] = React.useState()

  return (
    <MouseDataContext.Provider value={{mouseData, setMouseData}}>
      {children}
    </MouseDataContext.Provider>
  )
}

export const useMouseData = () => {
  const context = React.useContext(MouseDataContext)
  if (!context) throw new Error(`useMouseData must be used within a MouseDataProvider`)
  return context
}
