import {CCol, CRow} from '@coreui/react-pro'

const RowCol = ({children, className, style, colStyle, ...props}) => {
  return (
    <CRow className={className} style={style} {...props}>
      <CCol style={colStyle}>
        {children}
      </CCol>
    </CRow>
  )
}

export default RowCol
