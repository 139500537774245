import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

const useDarkMode = () => {
  const [darkMode, setDarkMode] = useState(0)
  const {hash} = useLocation()
  const params = Object.fromEntries(new URLSearchParams(hash.substring(1)))

  function updateDarkMode(enable) {
    if (params.darkMode === 'true') enable = true
    if (params.lightMode === 'true') enable = false
    setDarkMode(enable)
    if (enable) {
      document.body.classList.add('dark-mode')
    } else {
      document.body.classList.remove('dark-mode')
    }
  }

  useEffect(() => {
    // Add listener to update styles
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => updateDarkMode(e.matches))

    // Setup dark/light mode for the first time
    updateDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches)

    // Remove listener
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
      })
    }
  }, [])

  return {darkMode}
}

export default useDarkMode
