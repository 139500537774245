export const isObjEmpty = obj => !obj || Object.keys(obj).length === 0

export const flattenMetric = it => {
  const flat = {}
  Object.entries(it.metrics).forEach(([category, values]) => {
    Object.entries(values).forEach(([metric, val]) => {
      flat[`${category}:${metric}`] = val
    })
  })
  return flat
}

export const flatten = (it, prop) => {
  const value = it[prop]
  if (value) {
    Object.entries(value).forEach(([subkey, val]) => {
      it[subkey] = val
    })
    delete it[prop]
  }
  return it
}
