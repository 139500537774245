import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {randomString} from '../components/util/Language'
import {mapValues, pickBy, startsWith} from 'lodash'
import useWebSocket from './useWebSocket'
import moment from 'moment'
import {parseDataset} from '../util/text'
import {parseCandle} from '../api/Parsers'

export const MarketDataContext = React.createContext({})

// This aggregates all ticker / candle subscriptions, conntects the websocket to the market data API and amasses the
// candles as a ref (eg. new datapoints don't cause a state update).
export const MarketDataProvider = ({children}) => {
  const [_, setSubscriptions] = useState({tickerSubscriptions: {}, candleSubscriptions: {}})
  const ref = useRef({tickers: {}, candles: {}})

  const {websocket, connected} = useWebSocket('/ws/marketdata', ({data}) => {
    if (data.startsWith("Subscribed to")) return
    data = JSON.parse(data)
    data.dataset = parseDataset(data.dataset)
    data.received = moment()

    const isCandle = data.dataset.aggregation
    const target = (isCandle ? ref.current.candles : ref.current.tickers)
    if (isCandle) {
      data = parseCandle(data)
    } else {
      const previous = target[data.dataset.datasetString]
      data.increased = previous && previous.last < data.last
    }
    target[data.dataset.datasetString] = data
  })

  function addSubscription(existingSubscription, keys, subscription) {
    const updated = {...existingSubscription}
    keys.forEach(key => {
      const existing = existingSubscription[key] || []
      updated[key] = [...existing, subscription]
    })
    return updated
  }

  const sendSubscriptions = useCallback((tickers, candles) => {
    if (!websocket || !connected || websocket.readyState !== WebSocket.OPEN) return
    const tickersSubscribed = Object.keys(pickBy(tickers, subscriptions => subscriptions.length > 0))
    const candlesSubscribed = Object.keys(pickBy(candles, subscriptions => subscriptions.length > 0))
    websocket.send(`candles:${candlesSubscribed.join(',')}\ntickers:${tickersSubscribed.join(',')}\npartial:true`)
  }, [websocket, connected])

  const subscribeTo = useCallback(((tickers = [], candles = []) => {
    const subscription = randomString(12)
    setSubscriptions(({tickerSubscriptions, candleSubscriptions}) => {
      const updatedTickers = addSubscription(tickerSubscriptions, tickers, subscription)
      const updatedCandles = addSubscription(candleSubscriptions, candles, subscription)
      sendSubscriptions(updatedTickers, updatedCandles)
      return {tickerSubscriptions: updatedTickers, candleSubscriptions: updatedCandles}
    })
    return subscription
  }), [connected, setSubscriptions,  sendSubscriptions])

  const unsubscribeFrom = (subscription) => {

    setSubscriptions(({tickerSubscriptions, candleSubscriptions}) => {
      const updatedTickers = mapValues(tickerSubscriptions, existing => existing.filter(it => it !== subscription))
      const updatedCandles = mapValues(candleSubscriptions, existing => existing.filter(it => it !== subscription))
      sendSubscriptions(updatedTickers, updatedCandles)
      return {tickerSubscriptions: updatedTickers, candleSubscriptions: updatedCandles}
    })
  }

  return (
    <MarketDataContext.Provider value={{ref, subscribeTo, unsubscribeFrom}}>
      {children}
    </MarketDataContext.Provider>
  )
}

// This uses is a hook to be used by each component that will use the market data context, take in a list of
// subscriptions and only trigger a re-render if the data in the subscriptions has changed.
export const marketDataContext = (tickerSubscriptions = [], candleSubscriptions = [], updateFrequency = 1000) => {
  const {ref, subscribeTo, unsubscribeFrom} = useContext(MarketDataContext)
  const [{myTickers, myCandles, updated}, setMyData] = useState({})

  if (typeof tickerSubscriptions === 'string') tickerSubscriptions = [tickerSubscriptions]
  if (typeof candleSubscriptions === 'string') candleSubscriptions = [candleSubscriptions]
  if (tickerSubscriptions === null) tickerSubscriptions = []
  if (candleSubscriptions === null) candleSubscriptions = []
  // Used in useEffect to trigger a re-render.
  const subString = tickerSubscriptions.join(',') + '|' + candleSubscriptions.join(',')

  const checkData = useCallback((prefixes, latestData, myData, setMyData) => {
    if (!prefixes || prefixes.length === 0) return
    const updated = {}
    let changed = false
    prefixes.forEach(prefix => {
      const matchingPrefix = Object.entries(latestData)
        .filter(([key]) => startsWith(key, prefix))
      matchingPrefix.forEach(([dataset, latest]) => {
        const previous = myData[dataset]
        if (!previous) {
          changed ||= !!latest
        } else {
          changed ||= previous.volume !== latest.volume
        }
        updated[dataset] = latest
      })
    })
    if (changed) setMyData(updated)
  }, [ref, setMyData])

  const checkAll = useCallback(() => {
    checkData(tickerSubscriptions, ref.current.tickers, myTickers || {}, updated => setMyData({myTickers: updated, myCandles, updated: +new Date()}))
    checkData(candleSubscriptions, ref.current.candles, myCandles || {}, updated => setMyData({myTickers, myCandles: updated, updated: +new Date()}))
  }, [myTickers, myCandles, ref, setMyData, checkData, subString])

  useEffect(() => {
    let callback = setInterval(checkAll, updateFrequency)
    return () => clearInterval(callback)
  }, [checkAll])

  useEffect(() => {
    if (!subscribeTo) return
    if (tickerSubscriptions.length === 0 && candleSubscriptions.length === 0) return
    const subscription = subscribeTo(tickerSubscriptions, candleSubscriptions)
    return () => unsubscribeFrom(subscription)
  }, [subString, subscribeTo])

  return {tickers: myTickers || {}, candles: myCandles || {}, updated}
}
