import useAuth from './useAuth'
import {useCallback, useEffect, useState} from 'react'
import {sleep} from '../components/util/Language'

const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
const port = window.location.port

export default function useWebSocket(path, callback) {
  const {auth, authNonce} = useAuth()
  const [connected, setConnected] = useState()
  const [websocket, setWebsocket] = useState()

  const startWebsocket = useCallback(() => {
    const url = `${protocol}://${window.location.hostname}:${port}${path}`
    const webSocket = new WebSocket(url)
    webSocket.onmessage = it => it.data !== 'pong' && callback(it)
    // webSocket.onerror = (e) => console.error('Websocket error', e)
    webSocket.onclose = ({reason}) => console.log(`Websocket closed: ${reason}`)
    webSocket.onopen = () => setConnected(true)
    return webSocket
  }, [path])

  function closeWebsocket(interval, websocket) {
    console.info('Closed:', path)
    try {
      clearInterval(interval)
      websocket && websocket.close()
    } catch (e) {
    }
  }

  useEffect(() => {
    if (!path || !auth) return

    closeWebsocket()
    console.info('Opening:', path)
    const websocket = startWebsocket()
    websocket.randomId = Math.random()
    setWebsocket(websocket)

    const interval = setInterval(() => websocket?.readyState === 1 && websocket.send('ping'), 8000)

    return () => {
      closeWebsocket(interval, websocket)
    }
  }, [path, auth, authNonce])

  return {connected, websocket}
}
