import React from 'react'
import {Logo} from '../../components/Logo'
import {CCol} from '@coreui/react-pro'
import {Canvg} from 'canvg'

function download(filename, url) {
  const elem = window.document.createElement('a')
  elem.href = url
  elem.download = filename
  document.body.appendChild(elem)
  elem.click()
  document.body.removeChild(elem)
}

const render = async (id, png) => {
  const svg = document.querySelector(`#${id}`)
  const data = (new XMLSerializer()).serializeToString(svg)
  if (png) {
    const canvas = document.createElement('canvas')
    const canvg = await Canvg.from(canvas.getContext('2d'), data)
    canvg.start()
    canvas.toBlob(blob => download(`${id}.png`, window.URL.createObjectURL(blob)))
  } else {
    download(`${id}.svg`, `data:image/svg+xml;base64,${window.btoa(data)}`)
  }
}

const LogoWithDownload = ({id, ...props}) => {
  return (
    <CCol>
      <Logo id={id} {...props} />
      <br />
      <button onClick={() => render(id, true)}>PNG</button>
      <button onClick={() => render(id, false)}>SVG</button>
    </CCol>
  )
}

const Set = ({id, sizeMultiplier, ...props}) => (
  <div className="row m-3">
    <LogoWithDownload id={`${id}-400`} size={sizeMultiplier * 400} {...props} className="m-2" />
    <LogoWithDownload id={`${id}-200`} size={sizeMultiplier * 200} {...props} className="m-2" />
    <LogoWithDownload id={`${id}-100`} size={sizeMultiplier * 100} {...props} className="m-2" />
  </div>
)

const Logos = () => (
  <div className="row text-center p-5" style={{
    backgroundImage:
      'linear-gradient(45deg, #ddd 25%, transparent 25%), ' +
      'linear-gradient(-45deg, #ddd 25%, transparent 25%), ' +
      'linear-gradient(45deg, transparent 75%, #ddd 75%), ' +
      'linear-gradient(-45deg, transparent 75%, #ddd 75%)',
    backgroundSize: '50px 50px',
    backgroundPosition: '0 0, 0 25px, 25px -25px, -25px 0px',
  }}>
    <CCol>
      <Set id="square-opaque-light" sizeMultiplier={1} dark={false} wide={false} transparent={false} />
      <Set id="square-opaque-dark" sizeMultiplier={1} dark={true} wide={false} transparent={false} />
      <Set id="wide-opaque-light" sizeMultiplier={0.4} dark={false} wide={true} transparent={false} />
      <Set id="wide-opaque-dark" sizeMultiplier={0.4} dark={true} wide={true} transparent={false} />
    </CCol>
    <CCol>
      <Set id="square-transparent-light" sizeMultiplier={1} dark={false} wide={false} transparent={true} />
      <Set id="square-transparent-dark" sizeMultiplier={1} dark={true} wide={false} transparent={true} />
      <Set id="wide-transparent-light" sizeMultiplier={0.4} dark={false} wide={true} transparent={true} />
      <Set id="wide-transparent-dark" sizeMultiplier={0.4} dark={true} wide={true} transparent={true} />
    </CCol>
  </div>
)

export default Logos
