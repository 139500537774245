import React from 'react'
import {CSpinner} from '@coreui/react-pro'

export const Spinner = ({small, className}) => (
  <center>{small ? <CSpinner size="sm" className={className}/> : <CSpinner className={`m-5 ${className || ""}`} />}</center>
)

export const SpinnerContainer = ({children, loaded, small}) => {
  if (!loaded) return <Spinner small={small} style={small ? {} : {width: '100%', height: '100%'}} />
  return typeof children === 'function' ? children() : children
}
