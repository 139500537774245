import {createStore} from 'redux'

let storedState = localStorage.getItem('state')
if (storedState) storedState = JSON.parse(storedState)
const initialState = storedState || {
  sidebarUnfoldable: true,
}

const changeState = (state = initialState, {type, ...rest}) => {
  switch (type) {
    case 'set':
      return {...state, ...rest}
    default:
      return state
  }
}

const store = createStore(changeState)

store.subscribe(() => {
  localStorage.setItem('state', JSON.stringify(store.getState()))
})

export default store
