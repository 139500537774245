import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import PrivateRoute from './views/pages/PrivateRoute'
import {DatasetProvider} from './context/Dataset'

import './scss/style.scss'
import Logos from './views/logo/Logo'
import {determineEnv, EnvContext, LOCAL_ENV} from './hooks/EnvContext'
import {RefDataProvider} from './context/RefData'
import {ToastProvider} from './context/Toast'
import {FundProvider} from './context/Fund'
import {ConfigProvider} from './context/Config'
import {ErrorBoundary} from './components/util/ErrorBoundary'
import {DateRangeProvider} from './context/DateRange'
import {ConfirmationProvider} from './context/Confirmation'
import {ModalProvider} from './context/Modal'
import {WorkspaceProvider} from './context/Workspace'
import {ErrorProvider} from './hooks/useError'
import AuthProvider from './context/AuthProvider'
import {MarketDataProvider} from './hooks/MarketDataContext'
import {MouseDataProvider} from './context/MouseData'
import {StrategyProvider} from './hooks/useMetrics'

import {ClientProvider} from './context/ClientContext'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/Login'))
const Page404 = React.lazy(() => import('./views/pages/Page404'))
const Page500 = React.lazy(() => import('./views/pages/Page500'))

function App() {
  const env = determineEnv()

  let withProviders = (
    <ErrorBoundary>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/logo" render={(props) => <Logos {...props} />} />
          <Route exact path="/login" render={(props) => <Login {...props} />} />
          <Route exact path="/404" render={(props) => <Page404 {...props} />} />
          <Route exact path="/500" render={(props) => <Page500 {...props} />} />
          <PrivateRoute path="/" render={(props) => <DefaultLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </ErrorBoundary>
  )
  // Slot in each provider without building the great pyramid of providers
  withProviders = <MarketDataProvider>{withProviders}</MarketDataProvider>
  withProviders = <StrategyProvider>{withProviders}</StrategyProvider>
  withProviders = <MouseDataProvider>{withProviders}</MouseDataProvider>
  withProviders = <ConfigProvider>{withProviders}</ConfigProvider>
  withProviders = <ModalProvider>{withProviders}</ModalProvider>
  withProviders = <ConfirmationProvider>{withProviders}</ConfirmationProvider>
  withProviders = <ToastProvider>{withProviders}</ToastProvider>
  withProviders = <DateRangeProvider>{withProviders}</DateRangeProvider>
  withProviders = <DatasetProvider>{withProviders}</DatasetProvider>
  withProviders = <FundProvider>{withProviders}</FundProvider>
  withProviders = <WorkspaceProvider>{withProviders}</WorkspaceProvider>
  withProviders = <RefDataProvider>{withProviders}</RefDataProvider>
  withProviders = <EnvContext.Provider value={{env, local: env === LOCAL_ENV}}>{withProviders}</EnvContext.Provider>
  withProviders = <AuthProvider>{withProviders}</AuthProvider>
  withProviders = <ClientProvider>{withProviders}</ClientProvider>
  withProviders = <ErrorProvider>{withProviders}</ErrorProvider>

  return <Router>{withProviders}</Router>
}

export default App
