import React, {useEffect} from 'react'
import useForm from '../hooks/useForm'
import moment from 'moment'
import {parseDataset} from '../util/text'

const datasetDefault = {source: 'BN', base: 'BTC', quote: 'USDT', aggregation: 'PT8H'}
export const DatasetContext = React.createContext(datasetDefault)
const STORAGE_KEY = 'com.blackalto.dataset'

export const DatasetProvider = ({children}) => {
  const initialValues = localStorage.getItem(STORAGE_KEY) ? JSON.parse(localStorage.getItem(STORAGE_KEY)) : datasetDefault
  const {values, handleChange, setValue, setValues} = useForm({initialValues})
  const {source, base, quote} = values || {}

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(values))
  }, [values])

  const tradeDataset = (source && base && quote) ? `${source}-${base}-${quote}` : null

  const datasetString = values.aggregation !== 'null' ? `${tradeDataset}-${values.aggregation}` : tradeDataset
  const intervalDuration = values.aggregation && moment.duration(values.aggregation)

  const setDataset = (dataset) => setValues(parseDataset(dataset))

  return (
    <DatasetContext.Provider value={{dataset: values, intervalDuration, datasetString, tradeDataset, handleChange, setDataset, setValue}}>
      {children}
    </DatasetContext.Provider>
  )
}
